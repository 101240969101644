<script>
	import Access from "./Access.svelte";

	const API = "https://access-api.gameratoor.com/v1";
	let view = "read";

	let searchparams = new URLSearchParams("");

	if (window.location.search != "") {
		searchparams = new URLSearchParams(window.location.search);

		if (searchparams.get("view") != null) {
			view = searchparams.get("view").replace(/[^a-zA-Z\-\_]/g, "");
		}
	}

	try {
		fetch(API + "/ping")
			.then((res) => res.json())
			.then((d) => {
				if (d.status != "success") {
					view = "error";
				}
			})
			.catch((err) => {
				if (window.location.hostname != "localhost") {
					view = "offline";
				}
			});
	} catch (error) {
		if (window.location.hostname != "localhost") {
			view = "offline";
		}
	}

	let keys,
		objects = [];

	try {
		fetch(API + "/access_key/list", {
			method: "POST",
			body: new URLSearchParams({ ac: "access_key_list" }),
		})
			.then((res) => res.json())
			.then((aclist) => {
				keys = Array.from(aclist.access_keys);
			});
	} catch (error) {}

	try {
		fetch(API + "/object/list")
			.then((res) => res.json())
			.then((aclist) => {
				objects = Array.from(aclist.objects);
			});
	} catch (error) {}

	let writeid = "NONE";

	async function do_write() {
		write_status = "> Waiting for tag...";
		try {
			const ndef = new NDEFReader();
			await ndef.write(writeid);
			write_status = "> Message written";
		} catch (error) {
			write_status = "> Argh! " + error;
		}
	}

	let write_status,
		access_status = "> No Action";

	let is_reading = false;

	async function do_read() {
		is_reading = true;
		try {
			let ndef = new NDEFReader();
			await ndef.scan();
			access_status = "> Scan started";

			ndef.addEventListener("readingerror", () => {
				access_status = "Argh! Cannot read data from the NFC tag. Try another one?";
			});

			ndef.addEventListener("reading", ({ message, serialNumber }) => {
				let data = "";

				const record = message.records[0];
				const decoder = new TextDecoder();
				const textDecoder = new TextDecoder(record.encoding);

				data = textDecoder.decode(record.data);

				access_status = `> Tag Read.<br>> Serial Number: ${serialNumber}<br>> Data: ${JSON.stringify(data)}`;
			});
		} catch (error) {
			access_status = "Argh! " + error;
		}

		setTimeout(() => (is_reading = false), 1000);
	}

	setInterval(() => {
		if (view == "access" && is_reading == false) {
			do_read();
		}
	}, 1000);

	let read_selected = "";

	function do_read_select() {
		window.location.href = "?view=access&oid=" + read_selected;
	}
</script>

{#if view == "home" || view == "write" || view == "read"}
	<button class="btn btn-secondary" on:click={() => (view = "home")}>Home</button>
	<button class="btn btn-danger" on:click={() => window.location.replace("/")}>cancel</button>
{/if}

{#if view == "home"}
	<div class="container">
		<h2>Home</h2>

		<div class="row gap-1">
			<button class="btn btn-primary" on:click={() => (view = "write")}>Write</button>
			<button class="btn btn-primary" on:click={() => (view = "read")}>Read</button>
		</div>
	</div>
{:else if view == "offline"}
	<div class="container">
		<h1>Offline</h1>
	</div>
{:else if view == "write"}
	<div class="container">
		<h2>Write</h2>

		<select class="mb-3" id="write" bind:value={writeid}>
			<option value="NONE" selected disabled> None Selected </option>
			{#each keys as item (item.public_id)}
				<option value={item.public_id}>
					[{item.type}] {item.friendly_name}
				</option>
			{/each}
		</select>

		<button class="btn btn-success mb-3" on:click={do_write}>Start</button>

		<div class="alert alert-secondary mb-3" role="alert">
			{write_status}
		</div>
	</div>
{:else if view == "read"}
	<div class="container">
		<h2>Read</h2>

		<select bind:value={read_selected}>
			{#each objects as item (item.oid)}
				<option value={item.oid}>
					{item.name}
				</option>
			{/each}
		</select>

		<button class="mb-3 btn btn-success" on:click={() => do_read_select()}>Entry</button>
	</div>
{:else if view == "access"}
	<Access {API} {searchparams} />
{:else}
	not found
{/if}

<style>
</style>
