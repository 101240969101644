<script>
	export let API;

	export let searchparams;

	let mode = "none";

	let rotate = false;

	let is_reading = false;

	let out = "";

	function log(txt) {
		//out += "<br>" + txt;
	}

	async function do_read() {
		is_reading = true;
		rotate = true;

		mode = "none";

		try {
			let ndef = new NDEFReader();
			await ndef.scan();
			//log("> Scan started");

			ndef.addEventListener("readingerror", () => {
				mode = "red";
				log("> Reading error");
				setTimeout(() => {
					rotate = false;
					is_reading = false;
				}, 1000);
			});

			ndef.addEventListener("reading", async ({ message, serialNumber }) => {
				//log("> Reading started");

				mode = "blue";

				let data = "";

				const record = message.records[0];
				const decoder = new TextDecoder();
				const textDecoder = new TextDecoder(record.encoding);

				data = textDecoder.decode(record.data);

				try {
					await fetch(API + "/access_key/check_access", {
						method: "POST",
						body: new URLSearchParams({ pid: data, oid: searchparams.get("oid") }),
					})
						.then((res) => res.json())
						.then((res) => {
							if (res.status == "success") {
								mode = "green";
							} else {
								mode = "red";

								if (res.message != "forbidden") {
									log(JSON.stringify(res));
								}
							}

							setTimeout(() => {
								is_reading = false;
							}, 4000);
						});
				} catch (error) {
					log(error);
					setTimeout(() => {
						is_reading = false;
					}, 1000);
				}
			});
		} catch (error) {
			access_status = "Argh! " + error;
			mode = "red";

			setTimeout(() => {
				rotate = false;
				is_reading = false;
			}, 1000);
		}
	}

	setInterval(() => {
		if (is_reading == false) {
			do_read();
		}
	}, 1000);

	// your script goes here
</script>

<div class="page">
	<div class="element" class:rotate data-mode={mode} style="--rot: 0deg">
		<div
			class="element-inner"
			on:click={() => {
				if (!document.fullscreenElement) {
					document.documentElement.requestFullscreen();
				} else {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					}
				}
			}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24" /><path d="M7.76,16.24C6.67,15.16,6,13.66,6,12s0.67-3.16,1.76-4.24l1.42,1.42C8.45,9.9,8,10.9,8,12c0,1.1,0.45,2.1,1.17,2.83 L7.76,16.24z M16.24,16.24C17.33,15.16,18,13.66,18,12s-0.67-3.16-1.76-4.24l-1.42,1.42C15.55,9.9,16,10.9,16,12 c0,1.1-0.45,2.1-1.17,2.83L16.24,16.24z M12,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,10,12,10z M20,12 c0,2.21-0.9,4.21-2.35,5.65l1.42,1.42C20.88,17.26,22,14.76,22,12s-1.12-5.26-2.93-7.07l-1.42,1.42C19.1,7.79,20,9.79,20,12z M6.35,6.35L4.93,4.93C3.12,6.74,2,9.24,2,12s1.12,5.26,2.93,7.07l1.42-1.42C4.9,16.21,4,14.21,4,12S4.9,7.79,6.35,6.35z" /></svg>
			<span id="a">{@html out}</span>
		</div>
		<div class="element-cube" />
	</div>
</div>

<style>
	#a {
		color: white;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	}

	.page {
		background-color: #34495e;
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.element[data-mode="none"] .element-cube {
		background: linear-gradient(to right, rgb(135, 152, 153), var(--o));
	}

	.element[data-mode="green"] .element-cube {
		background: linear-gradient(to right, rgba(46, 204, 113, 1), var(--o));
	}

	.element[data-mode="red"] .element-cube {
		background: linear-gradient(to right, rgba(255, 0, 0, 1), var(--o));
	}

	.element[data-mode="blue"] .element-cube {
		background: linear-gradient(to right, rgba(52, 152, 219, 1), var(--o));
	}

	.element {
		position: relative;
		overflow: hidden;
		--wh: min(calc(100vw - 2rem), 50vh);
		--br: 4rem;
		--o: rgba(0, 0, 0, 0.1);
		height: var(--wh);
		width: var(--wh);
		border-radius: var(--br);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		background-color: transparent;
		padding: 1rem;

		transition: background-color 200ms ease;
	}

	.element.rotate .element-cube {
		animation: 2000ms rotate-cube linear infinite;
	}

	.element-inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		background-color: black;
		width: 100%;
		height: 100%;
		border-radius: calc(var(--br) - 15px);
		position: relative;
		z-index: 10;
	}

	svg {
		fill: white;
		width: 8rem;
		height: 8rem;
	}

	.element-cube {
		position: absolute;
		width: 150%;
		height: 150%;
		background: blue;
	}

	@keyframes rotate-cube {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
</style>
